import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../components/hero/hero-chevron";
import Button from "../../components/custom-widgets/button";
import VimeoVideoFrame from "../../components/custom-widgets/vimeo-video-frame";

import "./banking-careers.global.scss";

import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const IconsData = {
  "401k": "/images/icons/401k-icon.svg",
  bonusProgram: "/images/icons/bonus-program-icon.svg",
  employeeAssistance: "/images/icons/employee-assistance-icon.svg",
  healthDependentCare: "/images/icons/health-dependent-care-icon.svg",
  healthInsurance: "/images/icons/health-insurance-icon.svg",
  lifeInsurance: "/images/icons/life-insurance-icon.svg",
  longTermDisability: "/images/icons/long-term-disability-icon.svg",
  paidParentalLeave: "/images/icons/paid-parental-leave-icon.svg",
  stockOptions: "/images/icons/stock-options-icon.svg",
  timeOff: "/images/icons/time-off-icon.svg",
  tuitionReimbursement: "/images/icons/tuition-reimbursement-icon.svg"
};

const BankingCareers = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/about-us/banking-careers/hero-careers-110223-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/about-us/banking-careers/hero-careers-110223-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/about-us/banking-careers/hero-careers-110223-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/about-us/banking-careers/hero-careers-110223-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/about-us/banking-careers/hero-careers-110223-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/about-us/banking-careers/hero-careers-110223-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/about-us/banking-careers/hero-careers-110223-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "banking-careers-hero",
    ...getHeroImgVariables(imgData),
    altText: "WaFd Bank employee with walt",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Join Us!",
            class: "text-white mb-0 font-weight-bold"
          }
        },
        {
          id: 2,
          heading: {
            text: "Love what you do.",
            class: "text-white mb-0"
          }
        },
        {
          id: 3,
          heading: {
            text: "Make a difference.",
            class: "text-white"
          }
        },
        {
          id: 4,
          button: {
            id: "best-employer-cta",
            text: "Why We're the Best",
            url: "/about-us/best-employer",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: "Banking Careers at WaFd Bank",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Banking Careers at WaFd Bank"
      },
      {
        name: "description",
        content:
          "Grow your banking career at WaFd Bank! Learn more about our environment, culture, benefits and apply online today!"
      },
      {
        property: "og:title",
        content: "Banking Careers at WaFd Bank"
      },
      {
        property: "og:description",
        content:
          "Grow your banking career at WaFd Bank! Learn more about our environment, culture, benefits and apply online today!"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/banking-careers"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-careers-110223.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      active: true,
      title: "Careers"
    }
  ];

  const CustomBullet = ({ name }) => {
    return (
      <span className="fa-li">
        <img style={{ width: "32px" }} alt="" src={IconsData[name]} />
      </span>
    );
  };

  const whyWafdVideosData = [
    {
      id: "why-wafd-video-1",
      vimeoId: "896230427",
      class: "m-auto iframe w-100 border-radius-12",
      videoTitle: "Anni Pacis Discusses Her Journey Working with WaFd Bank"
    },
    {
      id: "why-wafd-video-2",
      vimeoId: "896286132",

      class: "m-auto iframe w-100 border-radius-12",
      videoTitle: "Anthony Hernando Discusses the Family Atmosphere at WaFd Bank"
    },
    {
      id: "why-wafd-video-3",
      vimeoId: "828437286",
      class: "m-auto iframe w-100 border-radius-12",
      videoTitle: "Grow Your Banking Career at WaFd Bank - Daniel Bennett Reviews His Experience Working at WaFd Bank"
    },
    {
      id: "why-wafd-video-4",
      vimeoId: "774481741",
      class: "m-auto iframe w-100 border-radius-12",
      videoTitle: "Grow Your Banking Career at WaFd Bank - Shana Owens Shares His Review of Working for WaFd Bank"
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Careers</h1>
        <h3>Find an exciting and rewarding career in the WaFd Bank family!</h3>
        <Button
          type="anchor"
          externalLink={true}
          id="search-job-listings-cta-1"
          showIcon={false}
          url="https://careers-wafd.icims.com/"
          text="Search Job Listings"
          class="btn-primary w-100 w-sm-auto"
        />
      </section>

      <section className="bg-light">
        <div className="container">
          <h2 className="text-success">Why WaFd? We love coming to work and it shows!</h2>
          <div className="row pt-3">
            {whyWafdVideosData.map((video) => (
              <div className="col-md-6 mb-3" key={video.id}>
                <VimeoVideoFrame {...video} />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-success text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <h2 className="text-white">Our Culture</h2>
              <h4>
                At WaFd Bank, we've taken the same long-term perspective as we have with our clients. We value our
                employees by investing in a healthy work/life balance, competitive compensation packages, a vibrant
                team-oriented environment centered on professional service, and 360-degree, open communication with your
                colleagues.
              </h4>
              <Button
                type="anchor"
                externalLink={true}
                showIcon={false}
                id="search-job-listings-cta-2"
                url="https://careers-wafd.icims.com/"
                text="Search Job Listings"
                class="btn-white w-100 w-sm-auto"
              />
            </div>
            <div className="col-md-6">
              <StaticImage
                src="../../images/thumbnail-wafd-group-photo-112723.jpg"
                alt="WaFd employees waving as a group in a WaFd Bank lobby"
                placeholder="blurred"
                quality="100"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container pb-0" id="banking-careers-benefits-section">
        <h2 className="text-green-60">At WaFd Bank you get all of these great benefits!</h2>
        <div className="row mt-4">
          <div className="col-sm-6">
            <ul className="fa-ul">
              <li className="pl-2 pb-2">
                <CustomBullet name="timeOff" />
                Paid time off for vacation, sick days and holidays
              </li>
              <li className="pl-2 pb-2">
                <CustomBullet name="healthInsurance" />
                Health insurance
              </li>
              <li className="pl-2 pb-2">
                <CustomBullet name="stockOptions" />
                Stock options
              </li>
              <li className="pl-2 pb-2">
                <CustomBullet name="bonusProgram" />
                Bonus programs
              </li>
              <li className="pl-2 pb-2">
                <CustomBullet name="401k" />
                Generous 4% 401(k) matching plus a generous 3% annual contribution, 7% total employer contribution
              </li>
              <li className="pl-2 pb-2">
                <CustomBullet name="paidParentalLeave" />
                Paid parental leave
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <ul className="fa-ul">
              <li className="pl-2 pb-2">
                <CustomBullet name="lifeInsurance" />
                Life and AD&amp;D insurance
              </li>
              <li className="pl-2 pb-2">
                <CustomBullet name="longTermDisability" />
                Long-term disability
              </li>
              <li className="pl-2 pb-2">
                <CustomBullet name="tuitionReimbursement" />
                Tuition reimbursement
              </li>
              <li className="pl-2 pb-2">
                <CustomBullet name="employeeAssistance" />
                Employee assistance programs
              </li>
              <li className="pl-2 pb-2">
                <CustomBullet name="healthDependentCare" />
                Pre-tax health and dependent-care spending plans
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row row-cols-1 row-cols-md-3 row-cols-xl-4">
            <div className="col col-md-12 col-xl mb-3 mb-md-0">
              <h3 className="text-center text-xl-left mb-2">
                We believe listening to our colleagues helps make WaFd a better place to work.
              </h3>
              <p class="text-gray-60 text-sm text-center text-xl-left mt-1">Glassdoor Ratings as of Nov, 2023</p>
            </div>
            <div className="col text-center mb-3 mb-md-0">
              <img src="../../images/icons/66-rating.svg" alt="" className="mb-3" />
              <h4 className="font-weight-bold mb-0">66% Recommend to a Friend</h4>
            </div>
            <div className="col text-center mb-3 mb-md-0">
              <img src="../../images/icons/86-rating.svg" alt="" className="mb-3" />
              <h4 className="font-weight-bold mb-0">86% Approve of CEO</h4>
            </div>
            <div className="col text-center mb-3 mb-md-0">
              <StaticImage
                src="../../images/employees/headshot-brent-beardall-gray5-110623.jpg"
                alt="Brent Beardall - President, Chief Executive Officer, and Vice Chairman"
                placeholder="blurred"
                quality="100"
                className="mb-3"
                style={{ width: "120px" }}
              />
              <h4 className="mb-0 font-weight-bold">Brent Beardall</h4>
              <h4 className="font-weight-normal mb-0">President & Chief Executive Officer</h4>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center justify-content-md-start">
              <div className="d-inline-block position-relative">
                <img
                  alt="Find us on Glassdoor."
                  src="https://www.glassdoor.com/pc-app/static/img/partnerCenter/badges/eng_BASIC_250x90.png"
                />
                <a
                  href="https://www.glassdoor.com/Overview/Working-at-WaFd-Bank-EI_IE2025.11,20.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="stretched-link"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container text-gray-60 text-sm">
        <p className="font-weight-bold">Transparency in Group Health Plan Coverage</p>
        <p>
          The following link contains information related to WaFd Bank's group health plans with machine readable files
          as required by the Affordable Care Act transparency rule. These files are intended by the Departments of
          Labor, Treasury and HHS to be used by members of the general public to promote health insurance consumerism
          and competition. They are not necessarily intended for use by Plan participants. EIN 91-0135860.
        </p>
        <p>
          Table of contents:{" "}
          <a id="health-insurance-disclosure-link" href="https://sawus2prdticmrfrgawa.z5.web.core.windows.net/">
            Regence Group Administrators - Machine Readable Files (windows.net)
          </a>
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default BankingCareers;
